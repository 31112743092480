export const URL_CORPORATE_TOP = 'https://corp.antaa.jp';
export const URL_CORPORATE_TERMS = 'https://corp.antaa.jp/terms';
export const URL_CORPORATE_PRIVACY_POLICY =
  'https://corp.antaa.jp/privacypolicy';
export const URL_COPYRIGHT_INFRINGEMENT_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSfWaISiyTxGyarkwU91rIUafQzSb_g4mVzPnboybvibrXEKrA/viewform';
export const URL_INQUIRY_FORM = 'https://note.com/antaa/n/n3ae2566cdbb4';
export const URL_INQUIRY_FORM_MULTI =
  'https://docs.google.com/forms/d/1SU0mVpUakAKQ9dhc0M7epJKghj93OFD04fR7Ez6_OrY/viewform';
const FOOTER_STORE_UTM = '&utm_source=antaa_slide_web&utm_medium=footer_link';
export const URL_FIRST_POST_SUPPORT_FORM =
  'https://forms.gle/wCD3k9Ucz82yp3YH6';
export const URL_STORE_GOOGLE = `https://play.google.com/store/apps/details?id=jp.antaa.qa.android.AntaaQa${FOOTER_STORE_UTM}`;
export const URL_STORE_APPLE = `https://apps.apple.com/jp/app/id1151798574?${FOOTER_STORE_UTM}`;
export const URL_IP_GUIDELINE_VIDEO =
  'https://antaa-public.s3.ap-northeast-1.amazonaws.com/slide/ip-guideline/20220817_slide_guideline_archive/20220817_slide_guideline_archive.m3u8';

export const APP_BANNER_CLOSED_LOCAL_STORAGE_KEY =
  'mobile_app_download_banner_closed';

export const TWITTER_ACCOUNT_CORPORATE = '@antaa__jp';

export const URL_ANTAA_FACEBOOK = 'https://www.facebook.com/antaa';

export const URL_ANTAA_TWITTER = 'https://twitter.com/antaa__jp';

export const DEFAULT_TITLE = 'Antaa Slide';

export const ARTICLE_PARTIAL_DISCLOSE_PAGE_COUNT = 3;

export const DEPARTMENT_ID_OTHER = 27;
export const DEPARTMENT_ID_OTHER_STRING = '27';

export const ARTICLE_LIKE_ANNOUNCE_NAME_LENGTH_LIMIT = 30;

export const ONDEMAND_PROFILE_UPDATE_STATUS_MAP = {
  SKIPPED: 'SKIPPED',
  INCOMPLETE: 'INCOMPLETE',
  UPDATED: 'UPDATED',
  DIDNT_UPDATE: 'DIDNT_UPDATE',
  INCOMPLETE_TWICE: 'INCOMPLETE_TWICE',
};

export const HOSPITAL_MAX_FETCH_NUM = 100;

export const AUTHOR_MAX_FETCH_NUM = 100;

export const POPULAR_AUTHOR_MAX_FETCH_NUM = 9;

export const REQUIRED_PRIVACY_POLICY_VERSION = '20240524';
export const REQUIRED_TERMS_OF_SERVICE_VERSION = '20231228';
export const REQUIRED_TERMS_OF_ANTAA_AI_ASSISTANT_VERSION = '20240409';
export const REQUIRED_COMMUNITY_GUIDELINE_VERSION = '20240516';

export type Special = {
  name: string;
  link: string;
  isExternalLink?: boolean;
  mainImage: string;
  horizontalBannerImage?: string;
  gaAction?: string;
  mobileBannerImage?: string;
  alt: string;
  tags: string[];
  andTags?: string[][]; // 複数のタグが含まれているスライドを指定する場合に使用、タグ一つでも可
  /**
   * スライド詳細の関連スライドを特集のtagsで取得する場合はtrue
   */
  relatedSlidesPreferSpecialTags?: boolean;
};

export type SpecialHasMobileBanner = Special & {
  mobileBannerImage: string;
};

// 特集のキーに順番を持たせるために、キー名はの配列で定義している
// この配列の順番が特集の優先度になる
export const SPECIALS_KEYS = [
  'residentHandbookCampaign',
  'ctdIld',
  'clinicalReasoning',
  'hakuseikaiPartners',
  'ritouiryoukaigi',
  'ibdUcCd',
  'medicalview',
  'homeMedicalCare',
  'privateHealthcare',
  'hyperkalemia',
  'hyperuricemia',
  'career',
  'residentInternalMedicine',
  'designatedIntractableAndRareDiseases',
] as const;

// SPECIALS_KEYSから特集のキーの型を生成
export type SpecialsKey = keyof typeof SPECIALS;

export const SPECIALS: {
  [key in (typeof SPECIALS_KEYS)[number]]: Special;
} = {
  ctdIld: {
    name: 'ctdIld',
    alt: '膠原病に伴う間質性肺疾患特集',
    link: '/special/ctd-ild',
    mainImage: '/images/special/ctd-ild_main_1300*720.png',
    tags: [],
    andTags: [
      ['CTD-ILD'],
      ['膠原病に伴う間質性肺疾患'],
      ['膠原病に伴う間質性肺炎'],
      ['膠原病', '間質性肺疾患'],
      ['膠原病', '間質性肺炎'],
      ['CTD', 'ILD'],
      ['関節リウマチ', '間質性肺疾患'],
      ['関節リウマチ', '間質性肺炎'],
      ['RA', '間質性肺疾患'],
      ['RA', '間質性肺炎'],
      ['全身性強皮症', '間質性肺疾患'],
      ['全身性強皮症', '間質性肺炎'],
      ['SSc', '間質性肺疾患'],
      ['SSc', '間質性肺炎'],
      ['皮膚筋炎', '間質性肺疾患'],
      ['皮膚筋炎', '間質性肺炎'],
      ['IIMs', '間質性肺疾患'],
      ['IIMs', '間質性肺炎'],
    ],
  },
  clinicalReasoning: {
    name: 'clinicalReasoning',
    alt: '診断推論特集',
    link: '/special/clinical-reasoning',
    mainImage: '',
    tags: [],
  },
  hakuseikaiPartners: {
    name: 'hakuseikai-partners',
    alt: '',
    link: 'https://hakuseikai-partners.com/',
    isExternalLink: true,
    mainImage: '/images/hakuseikai_hakuseikai_partners.png',
    tags: ['パートナーシップ制度'],
  },
  ibdUcCd: {
    name: 'ibdUcCd',
    alt: 'IBD特集',
    link: '/special/ibd-uc-cd',
    mainImage: '/images/special/ibd-uc-cd_main_1300*720.png',
    tags: ['IBD', '炎症性腸疾患', '潰瘍性大腸炎', 'UC', 'CD', 'クローン病'],
  },
  hyperkalemia: {
    name: 'hyperkalemia',
    alt: '高カリウム血症特集',
    link: '/special/fec15a463248d6a9',
    mainImage: '/images/special/hyperkalemia_main_500*281.png',
    horizontalBannerImage: '/images/special/hyperkalemia_banner_728*90.png',
    mobileBannerImage: '/images/special/hyperkalemia_banner_1200*240.png',
    tags: [
      '高カリウム血症',
      '高K血症',
      '腎不全',
      '糖尿病性ケトアシドーシス',
      '腎性糖尿病',
      '利尿薬',
      '高血圧',
      '人工透析',
      '血液透析',
      'ロケルマ',
      'アドレナリン作動薬',
      'エース阻害薬 / ARB薬',
      '代謝性アシドーシス',
      '心電図',
      'アルドステロン拮抗薬',
      '高カリウム',
      'カリウム',
      'カリウム吸着薬',
      'ケイキサレート',
      'アーガメイト',
    ],
  },
  hyperuricemia: {
    name: 'hyperuricemia',
    alt: '高尿酸血症特集',
    link: '/special/hyperuricemia',
    mainImage: '/images/special/hyperuricemia_main_1300*720.png',
    horizontalBannerImage: '/images/special/hyperuricemia_banner_728*90.png',
    mobileBannerImage: '/images/special/hyperuricemia_banner_1200*240.png',
    tags: [
      '高尿酸血症',
      '関節痛',
      '結晶性関節炎',
      '痛風',
      '尿酸',
      '尿酸検査',
      '血液検査',
      '尿検査',
      '尿酸降下薬',
      'URAT1阻害薬',
      'ユリス',
      'ベネシッド',
      'ユリノーム',
      'ウリアデック',
      'ザイロリック',
      'フェブリク',
      'コルヒチン',
      'ラスリテック',
      'ウラリット',
      '腎臓結石',
      '高血圧',
      '代謝症候群',
      '心血管疾患',
    ],
  },
  privateHealthcare: {
    name: 'privateHealthcare',
    alt: '【新たな選択肢】保険外診療特集',
    link: '/special/private-healthcare',
    mainImage: '/images/special/private-healthcare_main_1300*720.png',
    tags: ['保険外診療', '自由診療', '自費診療'],
  },
  homeMedicalCare: {
    name: 'home-medical-care',
    alt: '在宅医療特集',
    link: '/special/home-medical-care',
    mainImage: '/images/special/home-medical-care_main_1300*720.png',
    tags: ['在宅', '在宅医療'],
    relatedSlidesPreferSpecialTags: true,
  },
  career: {
    name: 'career',
    alt: '【キャリアWEBメディア】医師の「はたらく」を一緒に考える',
    link: 'https://forms.gle/KtoXacvo7CyuJ7kYA/',
    mainImage: '/images/career_main_20241028.png',
    tags: ['キャリア'],
  },
  medicalview: {
    name: 'medicalview',
    alt: '要点だけ最速でわかる麻酔科研修',
    link: 'https://www.medicalview.co.jp/catalog/ISBN978-4-7583-1311-7.html?utm_source=antaa&utm_medium=banner&utm_campaign=978-4-7583-1311-7_20241224&utm_id=978-4-7583-1311-7_20241224',
    isExternalLink: true,
    mainImage: '/images/medialview_youten_wakaru_masuikakenshu_20241225.png',
    tags: ['要点だけ最速でわかる麻酔科研修'],
  },
  ritouiryoukaigi: {
    name: 'ritouiryoukaigi',
    alt: '離島医療会議',
    link: 'https://ritouiryoukaigi20250308archive.peatix.com/',
    isExternalLink: true,
    mainImage: '/images/ritouiryou2024_archive_1920_1080.jpg',
    tags: ['離島医療'],
  },
  residentInternalMedicine: {
    name: 'resident-internal-medicine',
    alt: '（2025年度）研修医ローテ対策特集',
    link: '/special/resident-internal-medicine',
    mainImage: '/images/special/resident-internal-medicine_main_1300*720.png',
    tags: [],
  },
  designatedIntractableAndRareDiseases: {
    name: 'designatedIntractableAndRareDiseases',
    alt: '難病・希少疾患特集',
    link: '/special/designated-intractable-diseases-and-rare-diseases',
    mainImage:
      '/images/special/designated-intractable-and-rare-diseases_main_1300*720.png',
    tags: ['難病', '希少疾患'],
  },
  residentHandbookCampaign: {
    name: 'residentHandbookCampaign',
    alt: '#研修医の教科書 キャンペーン',
    link: '/special/resident-handbook-campaign',
    mainImage: '/images/special/resident_handbook_campaign_main_1300*720.png',
    tags: ['研修医の教科書'],
  },
};

export type MobileSpecialBar = {
  key: 'career' | 'partners';
  image: string;
  alt: string;
  link: string;
  tags: string[];
  // バナー画像のデフォルトサイズを1200x240とし、縦横非が違う場合はwidth, heightを指定する
  width?: number;
  height?: number;
};

export const MOBILE_SPECIAL_BARS: MobileSpecialBar[] = [
  {
    key: 'partners',
    image: '/images/banner_hakuseikai_partners.png',
    alt: '医療法人 白青会 パートナーシップ制度',
    link: 'https://hakuseikai-partners.com/',
    tags: ['パートナーシップ制度'],
    width: 720,
    height: 202,
  },
  {
    key: 'career',
    image: '/images/career_banner_20241028.png',
    alt: SPECIALS.career.alt,
    link: SPECIALS.career.link,
    tags: SPECIALS.career.tags,
  },
];

type TopSpecialElement = {
  trackingId: string;
  imgSrc: string;
  linkUrl: string;
  description: string;
};

export const TOP_SPECIAL_ELEMENTS: TopSpecialElement[] = [
  {
    trackingId: '151',
    imgSrc: '/images/carousel_img_20250207_ctd-ild.png',
    linkUrl: '/special/ctd-ild',
    description: '膠原病に伴う間質性肺疾患特集',
  },
  {
    trackingId: '153',
    imgSrc: '/images/special/resident_handbook_campaign_main_1300*720.png',
    linkUrl: '/special/resident-handbook-campaign',
    description: '#研修医の教科書 キャンペーン',
  },
  {
    trackingId: '149',
    imgSrc: '/images/carousel_img_20250130_resident_internal_medicine.png',
    linkUrl: '/special/resident-internal-medicine',
    description: '（2025年度）研修医ローテ対策特集',
  },
  {
    trackingId: '146',
    imgSrc: '/images/carousel_img_20250115_ibd-uc-cd.png',
    linkUrl: '/special/ibd-uc-cd',
    description: 'IBD特集',
  },
  {
    trackingId: '148',
    imgSrc: '/images/carousel_img_20250204_award.png',
    linkUrl: 'https://note.com/antaa/n/n188dbc9ac56b',
    description: '【Antaa Slide Award 2024】受賞スライドはこちら！',
  },
  {
    trackingId: '147',
    imgSrc: '/images/ritouiryou2024_archive_1920_1080.jpg',
    linkUrl: 'https://ritouiryoukaigi20250308archive.peatix.com/',
    description: '【3/31までアーカイブ申込受付中】離島医療会議',
  },
  {
    trackingId: '152',
    imgSrc:
      '/images/carousel_img_20250207_designated-intractable-and-rare-diseases.png',
    linkUrl: '/special/designated-intractable-diseases-and-rare-diseases',
    description: '「難病・希少疾患」特集',
  },
  {
    trackingId: '150',
    imgSrc: '/images/carousel_img_20250131_clinical-reasoning.png',
    linkUrl: '/special/clinical-reasoning',
    description: '診断推論特集',
  },
  {
    trackingId: '145',
    imgSrc: '/images/carousel_img_20241101_home_med.png',
    linkUrl: '/special/home-medical-care',
    description: '在宅医療特集',
  },
  {
    trackingId: '142',
    imgSrc: '/images/career_20240628.png',
    linkUrl: 'https://career.antaa.jp/',
    description: '【キャリアWEBメディア】医師の「はたらく」を一緒に考える',
  },
  {
    trackingId: '76',
    imgSrc: '/images/carousel_img_20230124_antaa_slide_academy_lp.png',
    linkUrl: '/docs/academy',
    description: '【Antaa Slide Academy】スライド投稿のノウハウを無料で学ぶ',
  },
  {
    trackingId: '141',
    imgSrc: '/images/carousel_img_20240624_hyperuricemia.png',
    linkUrl: '/special/hyperuricemia',
    description: '高尿酸血症特集',
  },
  {
    trackingId: '135',
    imgSrc: '/images/carousel_img_20240425_hyperkalemia_az.png',
    linkUrl: '/special/fec15a463248d6a9',
    description: '高カリウム血症',
  },
  {
    trackingId: '144',
    imgSrc: '/images/carousel_img_20240926_private_healthcare.png',
    linkUrl: '/special/private-healthcare',
    description: '【新たな選択肢】保険外診療特集',
  },
];

export const RESIDENT_INTERNAL_MEDICINE_ARTICLE_IDS: string[] = [
  'eab403cc875346cf',
  '4bbed7ab98be4b9f',
  'ba00107ad2274f8d',
  '654a9dea708940a1',
  '7f4b922bde0844d5',
  'cc445670e5d648e8',
  '091e5754133b4952',
  'ba025bae01234b1e',
  '255bd3705b924e85',
  '87deb1cdfda34b2b',
  'f7ee308253af4eaa',
  'af7cce16de0c47c7',
  '53d757c5cf0347c1',
  '2ee856972d9240b2',
  '6ac7dc0268c54285',
  'f1113c77c467447c',
  '4a3466993f524e06',
  'fd4b1c9c4fc34722',
  'e097b2a75e264a01',
  '153a2057813f40be',
];
