import { getPublicRuntimeConfig } from '../config';
import { AntaaAccountType } from './antaaAccountType';
import { AntaaAccountSubType } from './antaaAccountSubType';

function getSlideWebBaseUrl() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return slideWebBaseUrl;
}

export function createProfileImageRelativeUrl(
  userId: number,
  options?: { version?: number },
) {
  let url = `/api/profile/${userId}/image`;

  if (options?.version) {
    const p = new URLSearchParams();
    p.set('v', String(options.version));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createProfileImageUrl(
  userId: number,
  options?: { version?: number },
) {
  const relativeUrl = createProfileImageRelativeUrl(userId, options);
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}

function createProfileRelativeUrlByUserId(
  userId: number,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
) {
  let url = options?.asSelf ? `/profile/self` : `/profile/${userId}`;

  if (options?.defaultIsOpenBadgeModal) {
    const p = new URLSearchParams();
    p.set('defaultIsOpenBadgeModal', String(options.defaultIsOpenBadgeModal));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

function createProfileRelativeUrl(
  discloseId: string,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
) {
  let url = options?.asSelf ? `/profile/self` : `/profile/${discloseId}`;

  if (options?.defaultIsOpenBadgeModal) {
    const p = new URLSearchParams();
    p.set('defaultIsOpenBadgeModal', String(options.defaultIsOpenBadgeModal));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createProfileUrl(
  userId?: number,
  discloseId?: string,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
): string {
  // アカウント統合の移行期間が完了したらuserIdでのURL生成は削除する
  let relativeUrl: string;
  if (userId) {
    relativeUrl = createProfileRelativeUrlByUserId(userId, options);
  } else {
    relativeUrl = createProfileRelativeUrl(discloseId!, options);
  }
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}

export function createSelfProfileUrl({
  defaultIsOpenBadgeModal,
}: {
  defaultIsOpenBadgeModal?: boolean;
}) {
  return createProfileUrl(undefined, undefined, {
    defaultIsOpenBadgeModal,
    asSelf: true,
  });
}

export function createUserName(user: any): string {
  if (!user) return '';
  return user.userName || `${user.lastName ?? ''}${user.firstName ?? ''}`;
}

/**
 * Slide Webにログイン可能なユーザステータスか判定する
 */
export function isUnauthenticatedStatus(userStatus: string): boolean {
  return (
    userStatus !== 'REGISTERED' &&
    userStatus !== 'REVIEWING' &&
    userStatus !== 'INVALID' &&
    userStatus !== 'OK'
  );
}

/**
 * 医師認証、医学生認証が完了してるかどうか
 */
export function isLicenseVerified(user: Express.User | null): boolean {
  // 非ログイン時はfalse
  if (!user) return false;
  // Slideアカウントの場合は一律trueとする
  if (!user.discloseId) return true;
  return user.userStatus?.toUpperCase() === 'OK';
}

/**
 * ユーザーが法人アカウントかどうかを判定する関数
 */
export function isCorporationAccount(user?: Express.User | null): boolean {
  // 非ログイン時はfalse
  if (!user) return false;

  return user.antaaAccountType === AntaaAccountType.CORPORATION;
}

/**
 * ユーザーが製薬会社アカウントかどうかを判定する関数
 */
export function isPharmaceuticalAccount(user?: Express.User | null): boolean {
  // 非ログイン時はfalse
  if (!user) return false;

  // アカウントタイプとサブタイプを組み合わせて判定
  return (
    user.antaaAccountType === AntaaAccountType.CORPORATION &&
    user.antaaAccountSubType === AntaaAccountSubType.PHARMACEUTICAL
  );
}

/**
 * ユーザーが出版社アカウントかどうかを判定する関数
 */
export function isPublisherAccount(user?: Express.User | null): boolean {
  // 非ログイン時はfalse
  if (!user) return false;

  // アカウントタイプとサブタイプを組み合わせて判定
  return (
    user.antaaAccountType === AntaaAccountType.CORPORATION &&
    user.antaaAccountSubType === AntaaAccountSubType.PUBLISHER
  );
}

/**
 * ユーザーが法人（医療機関）アカウントかどうかを判定する関数
 */
export function isDoctorRecruiterAccount(user?: Express.User | null): boolean {
  // 非ログイン時はfalse
  if (!user) return false;

  // アカウントタイプとサブタイプを組み合わせて判定
  return (
    user.antaaAccountType === AntaaAccountType.CORPORATION &&
    user.antaaAccountSubType === AntaaAccountSubType.DOCTOR_RECRUITER
  );
}
