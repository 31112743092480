export type AntaaAccountSubTypeString = 'pharmaceutical' | 'publisher'| 'doctorRecruiter';

const PHARMACEUTICAL = 'pharmaceutical';
const PUBLISHER = 'publisher';
const DOCTOR_RECRUITER = 'doctorRecruiter';

export const AntaaAccountSubType: {
  PHARMACEUTICAL: AntaaAccountSubTypeString;
  PUBLISHER: AntaaAccountSubTypeString;
  DOCTOR_RECRUITER: AntaaAccountSubTypeString;
} = {
  PHARMACEUTICAL,
  PUBLISHER,
  DOCTOR_RECRUITER,
};
