import {
  ChevronDownIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  SearchIcon,
  TriangleDownIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  ResponsiveValue,
  Spacer,
  Text,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
  VStack,
  WrapItem,
} from '@chakra-ui/react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import getConfig from '../common/config';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import { useAppContext } from '../contexts/app';
import useAnnounce from '../hooks/useAnnounce';
import useApiClient from '../hooks/useApiClient';
import useIdApiClient from '../hooks/useIdApiClient';
import useAppRouter from '../hooks/useAppRouter';
import useImportantNotice from '../hooks/useImportantNotice';
// import useOndemandProfileUpdate from '../hooks/useOndemandProfileUpdate';
import { getPublicRuntimeConfig } from '../common/config';
import { isAccountIntegrated } from '../common/libs/accountIntegrationStatus';
import { isAntaaAccount } from '../common/libs/accountType';
import {
  isPharmaceuticalAccount,
  isDoctorRecruiterAccount,
} from '../common/libs/user';
import { useLogout } from '../hooks/useLogout';
import { AntaaAccountType } from '../common/libs/antaaAccountType';
import { AntaaAccountSubType } from '../common/libs/antaaAccountSubType';
import BadgeModal from './BadgeModal';
import { IoBookmark, IoNotifications } from './Icons';

function MenuItemLink({
  href,
  onClick,
  children,
}: {
  href: string;
  onClick?: () => void;
  children: string;
}) {
  return (
    <MenuItem onClick={onClick}>
      <NextLink href={href} passHref>
        <Link color="linkText" w="100%">
          {children}
        </Link>
      </NextLink>
    </MenuItem>
  );
}

type Department = {
  id: number;
  name: string;
  displayOrder: number;
  articlesCount: number;
};

type DepartmentByCategory = {
  [key: string]: Department[];
};

function MenuItemBadge({ userId }: { userId: number }) {
  const badgeDisclosure = useDisclosure();
  return (
    <MenuItem>
      <Link
        color="linkText"
        onClick={() => {
          sendAnalyticsEvent('badge', 'show', 'from_top_bar');
          badgeDisclosure.onOpen();
        }}
      >
        取得したバッジ
      </Link>
      <BadgeModal userId={userId} disclosure={badgeDisclosure} />
    </MenuItem>
  );
}

function MenuItemQaLink() {
  const {
    publicRuntimeConfig: { qaWebBaseUrl },
  } = getConfig();

  return (
    <MenuItem>
      <Link
        color="linkText"
        href={qaWebBaseUrl}
        isExternal
        onClick={() => sendAnalyticsEvent('link_to', 'qa', 'from_top_bar')}
      >
        Antaa QA <ExternalLinkIcon ml={1} mb={1} />
      </Link>
    </MenuItem>
  );
}

function MenuItemSpecial() {
  return (
    <MenuItem>
      <Link
        color="linkText"
        href={'/special'}
        rel="noopener"
        target="_self"
        isExternal
        onClick={() => sendAnalyticsEvent('link_to', 'special', 'from_top_bar')}
      >
        特集TOP
      </Link>
    </MenuItem>
  );
}

function MenuItemWeeklyRankingLink() {
  return (
    <MenuItem>
      <Link
        color="linkText"
        href={'/weekly-ranking-articles'}
        rel="noopener"
        target="_self"
        isExternal
        onClick={() =>
          sendAnalyticsEvent('link_to', 'weekly_ranking', 'from_top_bar')
        }
      >
        週間スライドランキング
      </Link>
    </MenuItem>
  );
}

function MenuItemHelpLink() {
  const noteAntaaHelpUrl = 'https://note.com/antaa/n/n2c814e46032c';
  return (
    <MenuItem>
      <Link
        color="linkText"
        href={noteAntaaHelpUrl}
        isExternal
        onClick={() => sendAnalyticsEvent('link_to', 'note', 'help_page')}
      >
        ヘルプ <ExternalLinkIcon ml={1} mb={1} />
      </Link>
    </MenuItem>
  );
}

function MenuItemFaqLink() {
  const noteAntaaFaqUrl = 'https://note.com/antaa/n/ne7676bee6c70';
  return (
    <MenuItem>
      <Link
        color="linkText"
        href={noteAntaaFaqUrl}
        isExternal
        onClick={() =>
          sendAnalyticsEvent('link_to', 'note', 'faq_page_from_navbar')
        }
      >
        FAQ <ExternalLinkIcon ml={1} mb={1} />
      </Link>
    </MenuItem>
  );
}

function ArticleUploadButtonLink() {
  const { isAuthenticated, hasDemoAuthority, user } = useAppContext();
  if (hasDemoAuthority || isPharmaceuticalAccount(user)) {
    return <></>;
  }
  return (
    <Box ml={1}>
      <NextLink href="/article/upload" passHref>
        <Button
          as="a"
          px={6}
          size="md"
          fontSize="sm"
          color="white"
          bg="brand.500"
          onClick={() =>
            sendAnalyticsEvent(
              'slide',
              'click',
              isAuthenticated ? 'upload_button' : 'upload_button_without_login',
            )
          }
        >
          スライド投稿
        </Button>
      </NextLink>
    </Box>
  );
}

function LoggedInMenuList({ user }: { user: Express.User }) {
  const { hasManageAuthority, accountIntegrationStatus } = useAppContext();
  const { proceedIdPage } = useIdApiClient();
  const { logout } = useLogout();
  const unlessAntaaAccount = !isAntaaAccount(user.accountType);
  const onClickExit = () => {
    sendAnalyticsEvent('profile', 'click', 'exit_button');
    proceedIdPage('/users/exit?fromService=slide', user.discloseId);
  };

  return (
    <MenuList zIndex="dropdown">
      <MenuItemLink
        href={`/profile/${user.discloseId || user.id}`}
        onClick={() => sendAnalyticsEvent('profile', 'click', 'mypage_button')}
      >
        プロフィール
      </MenuItemLink>
      {!isPharmaceuticalAccount(user) && (
        <>
          <MenuItemLink
            href="/article/upload"
            onClick={() =>
              sendAnalyticsEvent('slide', 'click', 'upload_button')
            }
          >
            スライド投稿
          </MenuItemLink>
          <MenuItemLink
            href="/mypage"
            onClick={() =>
              sendAnalyticsEvent('profile', 'click', 'setting_button')
            }
          >
            投稿したスライド
          </MenuItemLink>
          {!isDoctorRecruiterAccount && (
            <MenuItemLink
              href="/mypage/like"
              onClick={() =>
                sendAnalyticsEvent('slide', 'click', 'stock_button')
              }
            >
              保存したスライド
            </MenuItemLink>
          )}
        </>
      )}
      <MenuItemLink
        href="/mypage/view-history"
        onClick={() =>
          sendAnalyticsEvent('slide', 'click', 'show_history_button')
        }
      >
        閲覧したスライド
      </MenuItemLink>
      {!isPharmaceuticalAccount(user) && <MenuItemBadge userId={user.id} />}
      {unlessAntaaAccount && (
        <MenuItemLink
          href="/mypage/password"
          onClick={() =>
            sendAnalyticsEvent('profile', 'click', 'change_password_button')
          }
        >
          パスワード変更
        </MenuItemLink>
      )}
      <MenuItemHelpLink />
      <MenuItemFaqLink />
      <MenuDivider />
      {(user?.antaaAccountType !== AntaaAccountType.CORPORATION ||
        isDoctorRecruiterAccount(user)) && <MenuItemSpecial />}
      <MenuItemWeeklyRankingLink />
      <MenuDivider />
      <MenuItemQaLink />
      <MenuDivider />
      <MenuItem onClick={() => logout()}>ログアウト</MenuItem>
      {isAccountIntegrated(accountIntegrationStatus) &&
        !isPharmaceuticalAccount(user) &&
        !isDoctorRecruiterAccount(user) && (
          <MenuItemLink href="" onClick={onClickExit}>
            退会
          </MenuItemLink>
        )}
      {hasManageAuthority && (
        <>
          <MenuDivider />
          <MenuGroup title="管理者向け">
            <MenuItemLink href="/manager/users">ユーザー管理</MenuItemLink>
            <MenuItemLink href="/manager/articles">スライド管理</MenuItemLink>
            <MenuItemLink href="/manager/articles/upload">
              スライド アップロード
            </MenuItemLink>
          </MenuGroup>
        </>
      )}
    </MenuList>
  );
}

function ProfileImage({ user }: { user: Express.User }) {
  return (
    <Image
      src="/images/avatar_default_gray.png"
      // fallbackSrc="/images/avatar_default_gray.png"
      w="8"
      h="8"
      minW="8"
      minH="8"
      alt={`${user.lastName || ''}${user.firstName || ''}`}
      objectFit="cover"
      borderRadius="full"
    />
  );
}

function LoggedInMenu({ user }: { user: Express.User }) {
  const onOpen = () => {
    sendAnalyticsEvent('hamburger', 'click', 'loggedin');
  };
  return (
    <>
      {/* for Mobile */}
      <Box display={[null, null, null, 'none']}>
        <Menu placement="bottom-end" computePositionOnMount onOpen={onOpen}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon boxSize={6} />}
            variant="none"
          />
          <LoggedInMenuList user={user} />
        </Menu>
      </Box>

      {/* for PC */}
      <Box display={['none', null, null, 'block']}>
        <Menu placement="bottom-end" computePositionOnMount onOpen={onOpen}>
          <MenuButton
            as={Button}
            aria-label="Options"
            rightIcon={<ChevronDownIcon ms={-1} />}
            fontSize="sm"
            variant="none"
            px={0}
          >
            <ProfileImage user={user} />
          </MenuButton>
          <LoggedInMenuList user={user} />
        </Menu>
      </Box>
    </>
  );
}

function LoggedOutMenu() {
  const onOpen = () => {
    sendAnalyticsEvent('hamburger', 'click', 'loggedout');
  };
  return (
    <Menu placement="bottom-end" computePositionOnMount onOpen={onOpen}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon boxSize={6} />}
        variant="none"
      />
      <MenuList zIndex="dropdown">
        <MenuItemLink href="/login">ログイン</MenuItemLink>
        <MenuDivider />
        <MenuItemSpecial />
        <MenuItemWeeklyRankingLink />
        <MenuDivider />
        <MenuItemQaLink />
      </MenuList>
    </Menu>
  );
}

function SearchButton() {
  const { searchModalDisclosure } = useAppContext();
  const router = useAppRouter();
  const keyword = router.query.q
    ? yup.string().required().validateSync(router.query.q)
    : '';

  return (
    <>
      <InputGroup
        mr={3}
        w={[null, null, '35%', '35%']}
        display={['none', null, 'block']}
      >
        <Input
          borderColor="searchBar"
          bg="searchBar"
          css={{
            '&::-webkit-search-cancel-button': {
              display: 'none',
            },
          }}
          borderRadius="full"
          isReadOnly
          onClick={() => {
            sendAnalyticsEvent('search', 'click', 'search_input_from_navbar');
            searchModalDisclosure.onOpen();
          }}
          fontSize="sm"
          value={keyword}
          placeholder="キーワードでスライド検索"
        />
        <InputRightElement>
          <IconButton
            type="submit"
            aria-label="スライドを探す"
            icon={<SearchIcon color="blackAlpha.900" />}
            colorScheme="white"
            borderLeftRadius="none"
          />
        </InputRightElement>
      </InputGroup>
      <IconButton
        type="submit"
        aria-label="スライドを検索"
        icon={
          <>
            <VStack spacing={0}>
              <SearchIcon color="blackAlpha.600" boxSize={5} />
              <Text fontSize="xx-small" color="blackAlpha.600">
                検索
              </Text>
            </VStack>
          </>
        }
        variant="link"
        onClick={() => {
          sendAnalyticsEvent('search', 'click', 'search_input_from_navbar');
          searchModalDisclosure.onOpen();
        }}
        display={['block', null, 'none']}
        mt={2} // 縦方向の表示位置の微調整
      />
    </>
  );
}

function LikeArticlesButtonLink() {
  const { user } = useAppContext();
  if (isPharmaceuticalAccount(user) || isDoctorRecruiterAccount(user)) {
    return <></>;
  }
  return (
    <NextLink href="/mypage/like" passHref>
      <IconButton
        as="a"
        aria-label="保存リストを表示"
        icon={
          <>
            <VStack spacing={0} color="blackAlpha.600">
              <IoBookmark size={19} />
              <Text fontSize="xx-small">保存リスト</Text>
            </VStack>
          </>
        }
        variant="link"
        onClick={() => sendAnalyticsEvent('slide', 'click', 'stock_button')}
        mt={2} // 縦方向の表示位置を微調整
        mx={1}
      />
    </NextLink>
  );
}

function AnnounceButtonLink() {
  const { isAuthenticated, announceModalDisclosure } = useAppContext();
  const { fetchAnnounces, hasUnread } = useAnnounce();

  // モーダル開閉時の処理
  useEffect(() => {
    fetchAnnounces();
  }, [isAuthenticated, fetchAnnounces]);

  return (
    <IconButton
      as="a"
      href="#"
      aria-label="お知らせを表示"
      icon={
        <>
          <VStack spacing={0} color="blackAlpha.600">
            <IoNotifications size={20} />
            <Text fontSize="xx-small">お知らせ</Text>
          </VStack>
          {isAuthenticated && hasUnread && (
            <Box
              pos="absolute"
              right={2}
              top={0}
              w={2}
              h={2}
              borderRadius={'full'}
              bgColor={'red'}
              color="red"
            />
          )}
        </>
      }
      variant="link"
      onClick={(e) => {
        e.preventDefault();
        sendAnalyticsEvent('slide', 'click', 'announce_button');
        announceModalDisclosure.onOpen();
      }}
      mt={2} // 縦方向の表示位置を微調整
      mx={1}
    ></IconButton>
  );
}
function WrapSpecialItem({
  itemKey,
  url,
  text,
  onClick,
}: {
  itemKey: string;
  url: string;
  text: string;
  onClick: () => void;
}) {
  return (
    <WrapItem
      key={itemKey}
      w={{
        base: '100%',
        sm: 'calc(50% - 1rem)',
        md: 'calc(33.33% - 1rem)',
        lg: 'calc(25% - 1rem)',
      }}
    >
      <Link color="black" href={url} onClick={onClick}>
        <Text as="span" fontSize="sm" fontWeight="normal">
          {text}
        </Text>
        <ChevronRightIcon color="black" w={6} h={6} verticalAlign="middle" />
      </Link>
    </WrapItem>
  );
}
export default function NavBar({
  pos = 'fixed',
}: {
  pos?: ResponsiveValue<'fixed'>;
}) {
  const {
    user,
    isAuthenticated,
    importantNoticeModalDisclosure,
    // ondemandProfileUpdateModalDisclosure,
  } = useAppContext();
  const { colorMode } = useColorMode();
  const { fetchImportantNotice, hasImportantNoticeUnread } =
    useImportantNotice();
  /* 2023.08.15 オンデマンドプロフィール更新誘導無効化
  const {
    fetchOndemandProfileUpdate,
    incrementSuggestCountOndemandProfileUpdate,
    showOndemandProfileUpdateModal,
    idOndemandProfileUpdateModal,
  } = useOndemandProfileUpdate();
  */
  const pathname = useRouter().pathname;
  const isTopPage = pathname === '/';
  const isLoginPage = pathname === '/login';
  const isSpecialPage = pathname.startsWith('/special');

  let zIndex: ResponsiveValue<'sticky'> | undefined;
  if (pos === 'fixed') {
    zIndex = 'sticky';
  } else if (Array.isArray(pos)) {
    zIndex = pos.map((v) => (v === 'fixed' ? 'sticky' : null));
  }

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        await fetchImportantNotice();
        if (hasImportantNoticeUnread) {
          sendAnalyticsEvent('important_notice', 'open', null);
          importantNoticeModalDisclosure.onOpen();
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchImportantNotice, hasImportantNoticeUnread, isAuthenticated]);

  /* 2023.08.15 オンデマンドプロフィール更新誘導無効化
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        await fetchOndemandProfileUpdate();

        if (showOndemandProfileUpdateModal) {
          sendAnalyticsEvent('ondemand_profile_update', 'open', null);
          ondemandProfileUpdateModalDisclosure.onOpen();

          if (idOndemandProfileUpdateModal) {
            await incrementSuggestCountOndemandProfileUpdate();
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchOndemandProfileUpdate,
    incrementSuggestCountOndemandProfileUpdate,
    showOndemandProfileUpdateModal,
    idOndemandProfileUpdateModal,
    isAuthenticated,
  ]);
  */
  const { apiClient } = useApiClient();

  const [isClicked, setIsClicked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { departmentsPopoverDisclosure } = useAppContext();
  const toggleClick = () => {
    setIsClicked(!isClicked);
  };

  const borderBottomProps = useBreakpointValue({
    base: { borderBottomColor: 'navbarBottom', borderBottomWidth: 1 },
    md: { borderBottomColor: undefined, borderBottomWidth: 0 },
  });

  const shadowProps = useBreakpointValue({
    base: { shadow: 'navbar' },
    md: { shadow: undefined },
  });

  const LogoImage = () => (
    <NextImage
      src={
        colorMode === 'light'
          ? '/images/logo_slide.png'
          : '/images/logo_slide_white.png'
      }
      alt="Antaa Slide"
      width="164"
      height="33"
    />
  );

  return (
    <Box
      bg="navbar"
      {...borderBottomProps}
      {...shadowProps}
      pos={pos}
      zIndex={zIndex}
      width="100vw"
    >
      <Flex
        height={['56px', null, null, '70px']}
        py={26}
        px={[17, null, null, 39]}
        alignItems="center"
      >
        <Link
          href="/"
          display="flex"
          translateY="2px"
          transform="auto"
          flexShrink={0}
          _hover={{
            textDecoration: 'note',
            opacity: 0.8,
          }}
        >
          <Box height={[23, 33, 58, 57]} width={[122, 170, 170, 164]}>
            <Text
              fontSize="10"
              pl={1}
              pt={1}
              display={['none', 'none', 'flex', 'flex']}
              color="black"
            >
              医師・医学生のためのスライド共有
            </Text>
            {isTopPage ? (
              <Heading as="h1" lineHeight="none">
                <LogoImage />
              </Heading>
            ) : (
              <LogoImage />
            )}
          </Box>
        </Link>
        {!isLoginPage && (
          <Box
            display={{ base: 'none', md: 'block', lg: 'block', xl: 'block' }}
            ml={[2, null, 2, 8]}
            pos="relative"
          >
            <Flex justify="center" align-items="flex-start" align="center">
              <Box
                mr={[2, null, 2, 4]}
                borderBottomWidth={isOpen ? 2 : 0}
                borderColor="black"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleClick;
                  }}
                  onMouseEnter={() => {
                    departmentsPopoverDisclosure.onOpen();
                  }}
                  color="black"
                  display="flex"
                  alignItems="center"
                >
                  <Text as="span" fontSize="sm" fontWeight="normal" ms={1}>
                    診療科
                  </Text>
                  <Icon
                    as={TriangleDownIcon}
                    w={3}
                    h={3}
                    ms={1}
                    verticalAlign="bottom"
                  />
                </Link>
              </Box>
              {(user?.antaaAccountType !== AntaaAccountType.CORPORATION ||
                isDoctorRecruiterAccount(user)) && (
                <Box borderBottomWidth={0} borderColor="black">
                  <Link href="/special" color="black" pb={2}>
                    <Text
                      as="span"
                      fontSize="sm"
                      fontWeight="normal"
                      ms={1}
                      color={isSpecialPage ? '#008e14' : '#222'}
                    >
                      特集
                    </Text>
                  </Link>
                </Box>
              )}
            </Flex>
          </Box>
        )}
        <Spacer />
        <SearchButton />
        <AnnounceButtonLink />
        {!isAuthenticated && (
          <NextLink href="/login" passHref>
            <Button
              as="a"
              px={6}
              ml={2}
              size="md"
              fontSize="sm"
              color="white"
              bg="brand.500"
              onClick={() => sendAnalyticsEvent('link_to', 'click', 'login')}
            >
              ログイン
            </Button>
          </NextLink>
        )}
        {isAuthenticated ? (
          <>
            <LikeArticlesButtonLink />
            <Box pos="relative" zIndex="dropdown" ml={2}>
              <LoggedInMenu user={user!} />
            </Box>
            <Box display={['none', null, null, 'flex']}>
              <ArticleUploadButtonLink />
            </Box>
          </>
        ) : (
          <Box
            pos="relative"
            display={[null, null, null, 'none']}
            translateY="2px"
            transform="auto"
            zIndex="dropdown"
          >
            <LoggedOutMenu />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
